import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CreatePassword, ForgotPassword, SignIn, Signup, Home, Student, WorkSpace, Attendance, StudentDetails, Classes, Communication, Reports, FeesModule, FeesDetails } from "../../pages/Index";
import ProtectedRoute from "./ProtectedRoute";
import PageNotFound from "../../pages/pageNotFound/PageNotFound";
import TeacherComponent from "../../pages/teacher/Teacher.Component";
import Settings from "../../pages/settings/Settings";

const ApplicationNavigator = () => {
    return (
        <Router>
            <Routes>
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/sign-up" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/create-password" element={<CreatePassword />} />
                <Route path="/student" element={<ProtectedRoute Cmp={Student} />} />
                <Route path="/" element={<ProtectedRoute Cmp={Home} />} />
                <Route path="/classes" element={<ProtectedRoute Cmp={Classes} />} />
                <Route path="/communication" element={<ProtectedRoute Cmp={Communication} />} />
                <Route path="/reports" element={<ProtectedRoute Cmp={Reports} />} />
                <Route path="/settings" element={<ProtectedRoute Cmp={Settings} />} />
                <Route path="/fees" element={<ProtectedRoute Cmp={FeesModule} />} />
                <Route path="/attandence" element={<ProtectedRoute Cmp={Attendance} />} />
                <Route path="/workspace" element={<ProtectedRoute Cmp={WorkSpace} />} />
                <Route path="/teacher" element={<ProtectedRoute Cmp={TeacherComponent} />} />
                <Route path="/student/student-details" element={<ProtectedRoute Cmp={StudentDetails} />} />
                <Route path="/fees/fee-details" element={<ProtectedRoute Cmp={FeesDetails} />} />
                <Route path="/*" element={<PageNotFound />} />
            </Routes>
        </Router>
    )
}

export default ApplicationNavigator;