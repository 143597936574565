import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./SideNavbar.css";
import { SidebarContext } from "./SidebarContext";
import { Icons } from "../../constants/Icons";
import FirstLastNameAvatar from "../avatar/FirstLastNameAvatar";
import { api } from "../../services/commonApiService/CommonApiServices";
import { getApiUrl } from "../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import ToastBootstrap from "../toast/Toast";
import Swal from "sweetalert2";
import decodeJWT from "../../utils/Decode";
import { LogOutPost } from "../../redux/reduxToolkit/actions/authAction";
import { useDispatch, useSelector } from "react-redux";

const navItems = [
  { to: "/", text: "Home", icon: Icons.home },
  { to: "/classes", text: "Classes", icon: Icons.classes },
  { to: "/teacher", text: "Teachers", icon: Icons.teacher },
  { to: "/student", text: "Student", icon: Icons.students },
  { to: "/attandence", text: "Attendance", icon: Icons.attendence },
  { to: "/communication", text: "Communication", icon: Icons.communication },
  { to: "/reports", text: "Reports", icon: Icons.reports },
  { to: "/settings", text: "Settings", icon: Icons.settings },
  { to: "/fees", text: "Fees", icon: Icons.feesIcon },
];

const SideNavbar = () => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [adminAdminDetails, setAdminDetails] = useState("");
  const navigate = useNavigate();
  const { isOpen } = useContext(SidebarContext);
  const adminAuthToken = localStorage.getItem("token");
  const encodedToken = decodeJWT(adminAuthToken);
  const userId = encodedToken?.payload?.userId;
  const dispatch = useDispatch();
  const { logOutData, isLoadingLogOut, logOutError } = useSelector((state) => state?.auth);
  const payload = {
    authToken: adminAuthToken,
  };
  const logOutApi = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "Do you really want to log out?",
      showCancelButton: true,
      confirmButtonText: "Yes, log out",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(LogOutPost(payload))
      }
    });
    
  };
const fetchGetUser = async () => {
  try {
    const response = await fetch(
      `https://server.custoking.com:8080/kustoking/api/user/getUser/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authToken: adminAuthToken,
        },
      }
    );
    const data = await response.json();
    if (data.response === null) {
      return [];
    } else {
      setAdminDetails(data.response);
    }
  } catch (error) {
    console.error("Failed to fetch uploaded files:", error);
    throw error;
  }
};
useEffect(() => {
  fetchGetUser();
}, []);

return (
  <div className="sideNavbarBox">
    <div>
      <div className="profileNavSection">
        <div className="avatarMedium font12 fw600">
          <FirstLastNameAvatar
            name={
              adminAdminDetails &&
                adminAdminDetails.firstName &&
                adminAdminDetails.lastName
                ? `${adminAdminDetails.firstName} ${adminAdminDetails.lastName}`
                : "...."
            }
          />
        </div>
        <div className="ms-2">
          <p className="font12 fw600 nameText mb-0 text-capitalize">
            {adminAdminDetails &&
              adminAdminDetails.firstName &&
              adminAdminDetails.lastName
              ? `${adminAdminDetails.firstName} ${adminAdminDetails.lastName}`
              : null}
          </p>
          <p className="font10 fw600 degisnationProfile mb-0">
            {adminAdminDetails && adminAdminDetails.designation
              ? adminAdminDetails.designation
              : "...."}
          </p>
        </div>
      </div>
      <nav className={isOpen ? "main-menu main-menuopen" : "main-menu"}>
        <ul>
          {navItems?.map((item, index) => (
            <li key={index} className="has-subnav">
              <NavLink to={item.to} activeclassname="active">
                {/* <TooltipAnalytics text={item.text}> */}
                <div className="navsidebar">
                  <div className="navImagebox">
                    <img src={item.icon} className="img-fluid" alt="nav-img" />
                  </div>
                  <p className="nav-text mb-0">{item.text}</p>
                </div>
                {/* </TooltipAnalytics> */}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <div className="bottomLogout" onClick={logOutApi}>
      <img src={Icons.logout} className="img-fluid logoutImage" alt="bottom-img" />
      <p className="mb-0 font16 fw600 logoutText ms-2">Logout</p>
    </div>
    {errorMessage ? (
      <ToastBootstrap
        showErrorMessage={showErrorMessage}
        setShowErrorMessage={setShowErrorMessage}
        className={"toastErrorMessage"}
        message={errorMessage}
      />
    ) : null}
  </div>
);
};

export default SideNavbar;
