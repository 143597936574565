import { combineReducers, configureStore } from "@reduxjs/toolkit";
import studentReducer from "./slices/StudentSlice";
import attandenceReducer from "./slices/AttandenceSlice";
import authReducer from "./slices/authSlice";
import {
  personalDetailsIdReducer,
  academicDetailsIdReducer,
} from "./slices/storeWithoutApiCallSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  attandence: attandenceReducer,
  student: studentReducer,
  personalDetailsId: personalDetailsIdReducer,
  academicDetailsId: academicDetailsIdReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
