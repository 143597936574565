import React, { useState, useRef } from "react";
import "./Modal.css";
import { useDispatch } from "react-redux";
import { uploadDocumentFile } from "../../redux/reduxToolkit/actions/StudentAction"; // This can be updated to a generic file upload action
import { getCurrentTimestamp } from "../../DTO/TodayDate";
import { DocumentTypeData } from "../../DTO/DocumentTypeData";
import CommonDropdown from "../applicationDropdown/ApplicationDropdown";
import ApplicationButton from "../applicationButton/ApplicationButton";
import { Icons } from "../../constants/Icons";

const DocumentsModal = (props) => {
  const fileInputRefs = useRef({});
  const dispatch = useDispatch();
  const studentid = localStorage.getItem("studentDto");
  const createdDate = getCurrentTimestamp();

  const [documentRows, setDocumentRows] = useState([
    { documentType: "", document: null, errors: {} }
  ]); // Store rows data

  const handleDocumentFileChange = (event, index) => {
    const file = event.target.files[0];
    const fieldName = event.target.name;

    setDocumentRows((prevRows) => {
      const updatedRows = [...prevRows];
      const row = updatedRows[index];

      if (file) {
        // Validate file type (image, csv, pdf)
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf", "text/csv"];
        if (!allowedTypes.includes(file.type)) {
          row.errors[fieldName] = "Please upload a valid file (image, CSV, or PDF).";
          return updatedRows;
        }

        // Validate file size
        if (file.size > 5 * 1024 * 1024) {
          row.errors[fieldName] = "File size should be less than 5MB.";
          return updatedRows;
        }

        row.document = file;
        row.errors[fieldName] = "";

        // Trigger file upload API immediately after file is selected
        const customDocumentData = {
          uploadedDataTypeEnum: "CSV", // Update as per your requirement
          createdDate: createdDate,
          studentDTO: {
            id: studentid,
          },
          documentType: row.documentType, // Dynamic document type
        };

        const payload = {
          file: row.document,
          otherData: customDocumentData,
        };

        dispatch(uploadDocumentFile(payload)) // Assuming this action can be made generic
          .unwrap()
          .then((response) => {
            console.log("Upload successful for row", index, response);
          })
          .catch((error) => {
            console.error("Upload failed for row", index, error);
            setDocumentRows((prevRows) => {
              const updatedRows = [...prevRows];
              updatedRows[index].errors[fieldName] = error.message || "Upload failed. Please try again.";
              return updatedRows;
            });
          });
      }

      return updatedRows;
    });
  };

  const handleClick = (fieldName, index) => {
    setDocumentRows((prevRows) => {
      const updatedRows = [...prevRows];
      const row = updatedRows[index];

      // Check if document type is selected before allowing file upload
      if (!row.documentType) {
        row.errors.documentType = "Please select a document type before uploading a file.";
      } else {
        row.errors.documentType = ""; // Clear the error if document type is selected
        fileInputRefs.current[index][fieldName].click(); // Trigger file input if document type is selected
      }

      return updatedRows;
    });
  };

  const handleAddRow = () => {
    setDocumentRows((prevRows) => [
      ...prevRows,
      { documentType: "", document: null, errors: {} }
    ]);
  };

  const handleRemoveRow = (index) => {
    setDocumentRows((prevRows) => prevRows.filter((_, i) => i !== index));
  };

  // Get the document types that are already selected
  const getAvailableDocumentTypes = (index) => {
    const selectedDocumentTypes = documentRows.map(row => row.documentType).filter(Boolean);
    return DocumentTypeData.filter(
      (documentType) => !selectedDocumentTypes.includes(documentType) || documentType === documentRows[index].documentType
    );
  };

  // Check if all document types are selected
  const areAllDocumentTypesSelected = documentRows.length >= DocumentTypeData.length;

  return (
    <>
      <form>
        <div className="modalBodyBox">
          {documentRows?.map((row, index) => (
            <React.Fragment key={index}>
              <div className="row w-100">
                <div className="col-12">
                  <label className="label-text-style fw500 greyLabel">Document Type</label>
                </div>
              </div>
              <div className="row w-100">
                <div className="col-md-5">
                  <CommonDropdown
                    placeHolder={"Select Document Type"}
                    name="documentType"
                    customLabelStyle="greyLabel"
                    items={getAvailableDocumentTypes(index)} // Filter available document types
                    onSelect={(selectedValue) =>
                      setDocumentRows((prevRows) => {
                        const updatedRows = [...prevRows];
                        updatedRows[index].documentType = selectedValue;
                        updatedRows[index].errors.documentType = ""; // Clear error on selection
                        return updatedRows;
                      })
                    }
                  />
                  {row.errors.documentType && (
                    <div className="Inputerror">
                      {row.errors.documentType}
                    </div>
                  )}
                </div>
                <div className="col-md-7">
                  <div className="d-flex align-items-center">
                    <div className="file-input-container input-date-picker">
                      <button
                        type="button"
                        className="upload-button"
                        onClick={() => handleClick("document", index)}
                      >
                        Upload
                      </button>
                      <span>
                        {row.document ? row.document.name : "No file chosen"}
                      </span>
                      <input
                        type="file"
                        ref={(el) => {
                          fileInputRefs.current[index] = fileInputRefs.current[index] || {};
                          fileInputRefs.current[index].document = el;
                        }}
                        className="file-input"
                        accept=".pdf, .csv, image/*" // Allow images, CSV, and PDF
                        name="document"
                        onChange={(event) => handleDocumentFileChange(event, index)}
                        hidden
                      />
                    </div>
                    <div className="deleteIconBox" onClick={() => handleRemoveRow(index)}>
                      <img src={Icons.redDelete} className="deleteIconDocument" />
                    </div>
                  </div>
                  {row.errors.document && (
                    <div className="Inputerror">
                      {row.errors.document}
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="modalFooter modalDocumentFooter">
          <div className="leftButtonFooterBox">
            <ApplicationButton
              type="button"
              btnText="+ Add"
              onClick={handleAddRow}
              className={"whiteBtn40"}
              disabled={areAllDocumentTypesSelected} // Disable the button if all types are selected
            />
          </div>
          <div className="rightButtonFooterBox">
            <ApplicationButton
              type="button"
              btnText="Done"
              className={"blueheight40"}
              onClick={() => props.handleClose()}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default DocumentsModal;
