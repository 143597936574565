import React, { useEffect, useState } from "react";
import "../../../pages/user/student/Student.css";
import { Icons } from "../../../constants/Icons";
import ApplicationButton from "../../applicationButton/ApplicationButton";
import Modal from "react-bootstrap/Modal";
import StudentAddModalBody from "../../../pages/user/student/StudentAddModalBody";
import ToastBootstrap from "../../toast/Toast";
import { useCallback } from "react";
import _debounce from "lodash/debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMatchingStudentName,
  fetchStudentCount,
} from "../../../redux/reduxToolkit/actions/StudentAction";
import { clearMatchingStudentName } from "../../../redux/reduxToolkit/slices/StudentSlice";
import decodeJWT from "../../../utils/Decode";
import CommonDropdown from "../../applicationDropdown/ApplicationDropdown";
import { Dropdown } from "react-bootstrap";
import EmailAs from "../../modal/EmailAs.js";
const SecondHeaderStudent = ({ onSuccess }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const handleShowEmail = (title) => {
    setModalTitle(title);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const encodedToken = decodeJWT(token);
  const workspaceIdd = encodedToken?.payload?.workspaceId;
  useEffect(() => {
    dispatch(fetchStudentCount(workspaceIdd));
  }, [dispatch]);
  const { countStudent } = useSelector((state) => state?.student);

  const searchStudentName = async (inputValue) => {
    try {
      if (inputValue) {
        dispatch(fetchMatchingStudentName(inputValue));
      } else {
        dispatch(clearMatchingStudentName());
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const debounceFn = useCallback(
    _debounce((inputValue) => searchStudentName(inputValue), 1000),
    []
  );

  // Handle input change
  const handleChange = (event) => {
    const inputValue = event.target.value.trim();
    localStorage.setItem("eventResponse", inputValue);
    setValue(inputValue);
    debounceFn(inputValue);
  };
  return (
    <>
      <div className="secondHeader">
        <div className="leftSecondHeader">
          {countStudent > 0 ? (
            <>
              <div className="tagBox">
                <div className="tagImage">
                  <img src={Icons.tag} className="img-fluid" alt="tag" />
                </div>
                <p className="mb-0 font14 fw500 tagText">Tag</p>
              </div>
              <div className="totalStudentsCount">
                <p className="mb-0 font14 fw500 totalStudents">
                  {countStudent} Students
                </p>
              </div>
            </>
          ) : null}
        </div>
        <div className="rightSecondHeader">
          {countStudent > 0 ? (
            <div className="searchBox">
              <input
                name="studentName"
                placeholder="Enter Student Name"
                value={value}
                onChange={handleChange}
              />
              <div className="searchIcon">
                <img
                  alt="icon-search"
                  src={Icons.searchIcon}
                  className="img-fluid"
                />
              </div>
            </div>
          ) : null}

          <div className="addStudentButton">
            <ApplicationButton
              btnText="+ Add Student"
              className={"button40"}
              onClick={handleShow}
            />
          </div>
          <div className="shareBtnStudent">
            <Dropdown>
              <Dropdown.Toggle variant="undefined" id="dropdown-basic-student">
                Share
                <img
                  src={Icons.downArrow}
                  className="horizontalDot downarrowShareBtn"
                  alt="options"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="customDropdownMenu">
                <Dropdown.ItemText>Email All Pages</Dropdown.ItemText>
                <Dropdown.Item onClick={() => handleShowEmail("Email as CSV")}>As CSV</Dropdown.Item>
          <Dropdown.Item onClick={() => handleShowEmail("Email as PDF")}>As PDF</Dropdown.Item>
          <Dropdown.Item onClick={() => handleShowEmail("Email as Excel")}>As Excel</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <EmailAs show={showModal} handleClose={handleCloseModal} title={modalTitle} />
          </div>
          <div className="exportBtnStudent">
            <Dropdown>
              <Dropdown.Toggle variant="undefined" id="dropdown-basic-export">
                <img
                  src={Icons.export}
                  className="horizontalDot downarrowShareBtn"
                  alt="options"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="customDropdownMenu">
                <Dropdown.ItemText>Export All Pages</Dropdown.ItemText>
                <Dropdown.Item>As CSV</Dropdown.Item>
                <Dropdown.Item>As PDF</Dropdown.Item>
                <Dropdown.Item>As Exel</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          className="customModal"
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header>
            <div className="d-flex align-items-center justify-content-between w-100">
              <p className="mb-0 font16 fw500">Add Student</p>
              <div>
                <img
                  src={Icons.crossSky}
                  alt="cross"
                  className="crossModal"
                  onClick={handleClose}
                />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <StudentAddModalBody
              handleClose={handleClose}
              onSuccess={onSuccess}
            />
          </Modal.Body>
        </Modal>
      </div>
      {errorMessage ? (
        <ToastBootstrap
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={setShowErrorMessage}
          className={"toastErrorMessage"}
          message={errorMessage}
        />
      ) : null}
    </>
  );
};
export default SecondHeaderStudent;
