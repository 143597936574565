import { createSlice, createAction } from '@reduxjs/toolkit';
import { fetchGetAttandence } from '../actions/AttandenceAction';

const attandenceSlice = createSlice({
    name: 'attandence',
    initialState: {
        attandenceRecords: null,

        isLoadingdataStudentAttandenceStatus: false,

        dataStudentAttandenceError: null,
    },
    reducers: {
        attandenceState: (state) => {
            state.attandenceRecords = null;
    
            state.dataStudentAttandenceError= null;
        }
    },
    
    extraReducers: (builder) => {
        builder
            // Handle fetchStudentData
            .addCase(fetchGetAttandence.pending, (state) => {
                state.isLoadingdataStudentAttandenceStatus = true;
                state.dataStudentError = null;
            })
            .addCase(fetchGetAttandence.fulfilled, (state, action) => {
                state.isLoadingdataStudentAttandenceStatus = false;
                state.attandenceRecords = action.payload;
            })
            .addCase(fetchGetAttandence.rejected, (state, action) => {
                state.isLoadingdataStudentAttandenceStatus = false;
                state.dataStudentAttandenceError = action.payload;
            })

           
        
    },
});
// Export the reducer

export const { attandenceState } = attandenceSlice.actions;
export default attandenceSlice.reducer;
