import React, { useEffect } from "react";
import "./ForgotPassword.css";
import "../signUp/Signup.css";
import { Icons } from "../../constants/Icons";
import TextInputWithLabel from "../../components/applicationInput/ApplicationInput";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import GetCookie from "../../persistance/cookies/GetCookies";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address')
        .required("Please enter your email address"),
    }),
    onSubmit: async (values, { resetForm }) => {
      resetForm();
    },
  });
  useEffect(() => {
    const token = localStorage.getItem('token');
   if (token) {
      navigate("/");
      }
  }, []);
  return (
    <div className="signUpContainer">
      <div className="signUpTop forgotPasswordTop">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-md-6">
              <div className="rightSideSignUp rightSideForgetPassword">
                <div className="custokingLogo">
                  <img src={Icons.custokingLogo} className="img-fluid" />
                </div>
                <p className="text-white font30 fw600 signUpTo">
                  Forgot Password
                </p><p className="text-white font16 fw400">
                  Enter the email address associated to your account and we will
                  send a link to reset the password
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-md-6 position-relative">
              <div className="loginCard forgotPasswordCard">
                <div className="row">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="col-md-12">
                      <TextInputWithLabel
                        label={"Email Address"}
                        placeHolder={"Email Address"}
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        error={
                          formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-12 pt30">
                      <Link to="/create-password" className="link-decoration">
                        <ApplicationButton btnText={"Send Link"} type="submit" />
                      </Link>
                    </div>
                  </form>
                  <div className="col-md-12 pt30 text-center">
                    <p className="mb-0 font14 fw600">
                      Back to{" "}
                      <Link to="/sign-in" className="link-decoration">
                        Login
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
