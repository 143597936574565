import { useEffect, useState } from "react";
import FirstLastNameAvatar from "../../components/avatar/FirstLastNameAvatar";
import "./AttandenceTable.css";
import decodeJWT from "../../utils/Decode";
import { getApiUrl, getLoginUrl } from "../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import { api } from "../../services/commonApiService/CommonApiServices";
import { useFormik } from "formik";
import ToastBootstrap from "../../components/toast/Toast";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentData } from "../../redux/reduxToolkit/actions/StudentAction";
import CommonSpinner from "../../components/spinner/Spinner";

const AttendanceTable = () => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [attendanceData, setAttendanceData] = useState({});
  const [studentsData, setStudentData] = useState([]);
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const encodedToken = decodeJWT(token);
  const workspaceIdd = encodedToken?.payload?.workspaceId;

  const formik = useFormik({
    initialValues: {
      attendance: {},
    },
    onSubmit: async (values, { resetForm }) => {
      const unmarkedStudents = studentsData.filter(
        (student) => !attendanceData[student.id]
      );
      if (unmarkedStudents.length > 0) {
        setErrorMessage(
          `The following students have not been marked for attendance:\n\n` +
          unmarkedStudents
            .map((student) => `ID: ${student.id}, Name: ${student.firstName} ${student.lastName}`)
            .join("\n") +
          `\n\nPlease mark their attendance before saving.`
        );
        setShowErrorMessage(true);
        return;
      }

      const payloadAttandenceStudent = studentsData.map((student) => ({
        studentDTO: {
          id: student.id,
        },
        attendanceStatus: attendanceData[student.id] || "ABSENT",
      }));

      try {
        const response = await api.userPost(
          getLoginUrl() + apiUrls.attandence.markAttendence,
          payloadAttandenceStudent
        );
        if (response?.data?.httpStatusCode === 200) {
          resetForm();
        } else {
          setErrorMessage(response?.data?.message || "An error occurred");
          setShowErrorMessage(true);
          resetForm();
        }
      } catch (error) {
        setErrorMessage(
          error.response?.data?.message ||
          "An unexpected error occurred. Please try again."
        );
        setShowErrorMessage(true);
        console.error("Network or unexpected error:", error);
      }
    },
  });

  const handleAttendanceChange = (studentId, status) => {
    setAttendanceData({
      ...attendanceData,
      [studentId]: status,
    });
  };

  useEffect(() => {
    dispatch(fetchStudentData(workspaceIdd));
  }, [dispatch, workspaceIdd]);

  const { dataStudent, isLoadingdataStudentStatus, dataStudentError, matchStudent, isLoadingmatchStudentStatus, matchStudentError } = useSelector((state) => state?.student);
  const { attandenceRecords, isLoadingdataStudentAttandenceStatus, dataStudentAttandenceError } = useSelector((state) => state?.attandence);
  const inputSearchStudent = localStorage.getItem("eventResponse");

  useEffect(() => {
    if (inputSearchStudent?.length > 0) {
      setStudentData(Array.isArray(matchStudent) ? matchStudent : []);
    } else {
      setStudentData(Array.isArray(dataStudent) ? dataStudent : []);
    }
  }, [dataStudent, matchStudent, inputSearchStudent]);

  return (
    <>
      {!isLoadingdataStudentStatus && !isLoadingmatchStudentStatus ? (
        <form onSubmit={formik.handleSubmit}>
          {Array.isArray(studentsData) && studentsData.length > 0 ? (
            <table>
              <tbody>
                {studentsData.map((student) => (
                  <tr key={student.id}>
                    <td>
                      <div className="d-flex align-items-center paddingtdLeft10">
                        <div className="avatarCircle30 font10 fw600">
                          <FirstLastNameAvatar
                            name={`${student.firstName} ${student.lastName}`}
                          />
                        </div>
                        <span className="ms-2 text-capitalize">
                          {student.firstName} {student.lastName}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="ms-2 text-lowercase">{student.email}</span>
                      </div>
                    </td>
                    <td>
                      <div className="radio-group-attandence">
                        <input
                          type="radio"
                          name={`attendance-${student.id}`}
                          onChange={() =>
                            handleAttendanceChange(student.id, "PRESENT")
                          }
                          id={`presentAttendence-${student.id}`}
                        />
                        <label
                          className="radio-container-attandence present"
                          htmlFor={`presentAttendence-${student.id}`}
                        >
                          <span className="checkmark">Present</span>
                        </label>

                        <input
                          type="radio"
                          name={`attendance-${student.id}`}
                          onChange={() =>
                            handleAttendanceChange(student.id, "ABSENT")
                          }
                          id={`absentAttendence-${student.id}`}
                        />
                        <label
                          className="radio-container-attandence absent"
                          htmlFor={`absentAttendence-${student.id}`}
                        >
                          <span className="checkmark">Absent</span>
                        </label>

                        <input
                          type="radio"
                          name={`attendance-${student.id}`}
                          onChange={() =>
                            handleAttendanceChange(student.id, "LATE")
                          }
                          id={`lateAttendence-${student.id}`}
                        />
                        <label
                          className="radio-container-attandence late"
                          htmlFor={`lateAttendence-${student.id}`}
                        >
                          <span className="checkmark">Late</span>
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center mt-2">Data not found</p>
          )}
          {studentsData && studentsData.length > 0 && (
            <div className="col-md-2 ml-auto">
              <ApplicationButton btnText={"Save"} type="submit" />
            </div>
          )}
        </form>
      ) : (
        <CommonSpinner/>
      )}
      {errorMessage && (
        <ToastBootstrap
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={setShowErrorMessage}
          className={"toastErrorMessage"}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default AttendanceTable;
