import React from "react";
import "./ApplicationHeader.css";
import { Icons } from "../../constants/Icons";

const ApplicationHeader = ({ title, currentDate, calender }) => {
  return (
    <div className="applicationHeaderContainer">
      <div className="leftSideHeader">
        <h3 className="font20 fw600 mb-0">{title}</h3>
        {calender ? (
          <div className="attandence-header-item">
            <img src={Icons.blueCalenderIcon} alt="calendar-blue-icon" />
              <p className="mb-0 font14 fw600 ms-2">
                {currentDate} 
              </p>
          </div>
        ) : null}
      </div>
      <div className="rightSideHeader"></div>
    </div>
  );
};

export default ApplicationHeader;
