
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./Student.css"
import StudentOverView from './StudentOverview';
import "./StudentPerformance.css";
import SubjectRegister from './SubjectRegister';
import StudentDocuments from './studentsDetails/StudentDocuments';
import EmptyOverView from '../../../emptyPages/student/EmptyOverview';
import FeesTab from './Feestab';
const arrayTest=[]
const StudentPerformance = () => {
  return (
    <div className='studentPerformace'>
      <div className='row w-100'>
        <div className='col-12'>
          <Tabs
            defaultActiveKey="OverView"
            className="Performace"
            fill
          >
            <Tab eventKey="OverView" title="Overview" >
             {arrayTest.length>0?
          
             <StudentOverView />:<EmptyOverView/>
            }
            </Tab>
            <Tab eventKey="Subject" title="Subject">
              <SubjectRegister/>
            </Tab>
            <Tab eventKey="Grade" title="Grade">
              Grade
            </Tab>
            <Tab eventKey="Attendence" title="Attendence" >
              Attendence
            </Tab>
            <Tab eventKey="Fees" title="Fees" >
            <FeesTab/>
            </Tab>
            <Tab eventKey="Document" title="Document">
              <StudentDocuments/>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default StudentPerformance;



