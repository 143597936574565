import React from "react"
import UserLayout from "../../components/layout/UserLayout"
import ApplicationHeader from "../../components/applicationHeader/ApplicationHeader"


const TeacherComponent=()=>{
  localStorage.removeItem("eventResponse");
return(<>
 <UserLayout>
            <ApplicationHeader title="Teacher"/>
          <p>Comming Soon</p>
        </UserLayout>
</>)
}

export default TeacherComponent