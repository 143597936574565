import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as Yup from 'yup';
import ApplicationButton from '../../../components/applicationButton/ApplicationButton';
import TextInputWithLabel from '../../../components/applicationInput/ApplicationInput';
import './Student.css';
import { Icons } from '../../../constants/Icons';
import decodeJWT from '../../../utils/Decode';
import { api } from '../../../services/commonApiService/CommonApiServices';
import { getApiUrl } from '../../../services/urlConfig/UrlConfig';
import { apiUrls } from '../../../services/endPoints/ApiUrls';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudentData, postAddStudent } from '../../../redux/reduxToolkit/actions/StudentAction';

const StudentAddModalBody = ({ handleClose, onSuccess }) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [students, setStudents] = useState([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const token = localStorage.getItem("token");
    const decodedtoken = decodeJWT(token);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {addStudent, isLoadingAddStudent, addStudentError} = useSelector((state) => state?.student);

    const addStudentCall = () => {
        const payload = students.map((item) => {
            return {
                "studentId": item.studentId,
                "class": item.class,
                "firstName": item.firstName,
                "lastName": item.lastName,
                "email": item.email,
                "phone": item.mobile1,
                "mobile2": item.mobile2,
                "address": item.address,
                "photoUri": "s3://custoking/photos",
                "workspaceDTO": {
                    "id": decodedtoken.payload.workspaceId,
                    "organizationDTO": {
                        "id": decodedtoken.payload.organizationId
                    }
                }
            };
        });
        dispatch(postAddStudent(payload));
        setHasSubmitted(true);
    };
    const formik = useFormik({
        initialValues: {
            studentId: '',
            firstName: '',
            lastName: '',
            email: '',
            mobile1: '',
            image: null,
            address: ''
        },
        validationSchema: Yup.object({
            studentId: Yup.string().required('Student Id is required'),
            firstName: Yup.string().required('First Name is required'),
            lastName: Yup.string().required('Last Name is required'),
            email: Yup.string()
                .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Please give valid email address')
                .required('Email is required'),
            mobile1: Yup.string()
                .matches(/^\d{10}$/, 'Please give valid Mobile number')
                .required('Mobile number is required'),
            image: Yup.mixed().required('Image is required'),
            address: Yup.string().required('Address is required')
        }),
        onSubmit: (values, { resetForm }) => {
            setStudents(prevData => [...prevData, values]);
            resetForm();
        },
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                formik.setFieldValue('image', reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDeleteStudent = (index) => {
        setStudents(prevData => prevData.filter((_, i) => i !== index));
    };
    useEffect(() => {
        if (hasSubmitted) {
        if (addStudent?.httpStatusCode === 200) {
          handleClose();
           window.location.reload();
          formik.resetForm();
          Swal.fire({
            icon: 'success',
            title: 'Student Added SuccessFully',
            text: 'Student Added SuccessFullyd',
            confirmButtonText: 'OK',
            timer: 5000,
            timerProgressBar: true,
          });
        } else if (addStudentError) {
          setErrorMessage(addStudentError);
           setShowErrorMessage(true);
        }
      }
      }, [hasSubmitted, addStudent, addStudentError]);
    return (
        <div>
            <div className="d-flex borderBottomDashed">
                <div className="text-center">
                    <p className="mb-0 font10">Add Image</p>
                    <div className="imageContainer mt-2">
                        <input
                            type="file"
                            accept="image/*"
                            id="fileInput"
                            className="file-input"
                            onChange={handleImageChange}
                        />
                        <label htmlFor="fileInput" className="file-label">
                            <img src={formik.values.image ? formik.values.image : Icons.imageCamera} alt="Select file" className="img-fluid" />
                        </label>
                        {formik.touched.image && formik.errors.image && <div className="Inputerror">{formik.errors.image}</div>}
                    </div>
                </div>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row w-100 paddingLeft20">
                            <div className="col-md-6 paddingBottom6">
                                <TextInputWithLabel
                                    label="Enter Student ID"
                                    name="studentId"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.studentId}
                                    error={formik.touched.studentId && formik.errors.studentId ? formik.errors.studentId : null}
                                />
                            </div>
                            <div className="col-md-6 paddingBottom6">
                                <TextInputWithLabel
                                    label="First Name"
                                    name="firstName"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                                    error={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
                                />
                            </div>
                            <div className="col-md-6 paddingBottom6">
                                <TextInputWithLabel
                                    label="Last Name"
                                    name="lastName"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                    error={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
                                />
                            </div>

                            <div className="col-md-6 paddingBottom6">
                                <TextInputWithLabel
                                    label="Mobile Number"
                                    name="mobile1"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.mobile1}
                                    error={formik.touched.mobile1 && formik.errors.mobile1 ? formik.errors.mobile1 : null}
                                />
                            </div>
                            <div className="col-md-12 paddingBottom6">
                                <TextInputWithLabel
                                    label="Email"
                                    name="email"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    error={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                                />
                            </div>
                            <div className="col-md-12 paddingBottom6">
                                <TextInputWithLabel
                                    label="Address"
                                    name="address"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                    error={formik.touched.address && formik.errors.address ? formik.errors.address : null}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center paddingTop30">
                            <div>
                                <ApplicationButton btnText={"Add Student"} className={"button40 d-flex align-items-center"} type="submit" icon={Icons.addStudent} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {students?.length > 0 ?
                <div className="studentTableContainer">
                    <table className="studentTable">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Image</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Mobile Number</th>
                                <th>Address</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {students?.map((student, index) => (
                                <tr key={index}>
                                    <td>{student.studentId}</td>
                                    <td>{student.image && <img src={student.image} alt="student" className="studentImage" />}</td>
                                    <td>{student.firstName}</td>
                                    <td>{student.lastName}</td>
                                    <td>{student.email}</td>
                                    <td>{student.mobile1}</td>
                                    <td>{student.address}</td>
                                    <td>
                                        <div onClick={() => handleDeleteStudent(index)} className='text-center'><img src={Icons.delete} alt='delete' className='deleteButton' /></div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                : null}
            <div className='btnGroup'>
                <div>
                    <ApplicationButton btnText="Close" onClick={handleClose} className={"whiteBtn40"} />
                </div>
                <div className='paddingLeft20'>
                    <ApplicationButton btnText="Save" disabled={students.length>0 ? false : true} onClick={addStudentCall} className={"blueheight40"} />
                </div>
            </div>
        </div>
    );
};

export default StudentAddModalBody;
