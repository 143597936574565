import React, { useEffect, useState } from "react";
import './SignIn.css';
import { Icons } from "../../constants/Icons";
import TextInputWithLabel from "../../components/applicationInput/ApplicationInput";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { api } from "../../services/commonApiService/CommonApiServices";
import { getLoginUrl } from "../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import enCodeJwt from "../../utils/Encode";
import SetCookie from "../../persistance/cookies/SetCookies";
import GetCookie from "../../persistance/cookies/GetCookies";
import ToastBootstrap from "../../components/toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { SignInDataPost } from "../../redux/reduxToolkit/actions/authAction";
const SignIn = () => {
    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const {signInData, isLoadingSignInData, signInError} = useSelector((state) => state?.auth);
    console.log(signInData, isLoadingSignInData, signInError);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleChange = (e) => {
        setChecked(e.target.checked);
    };
  console.log(signInData,"signInDataaa")

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: yup.object({
            email: yup
                .string()
                .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address')
                .required("Please enter your email address"),
            password: yup
                .string()
                .min(7, "Password must be at least 7 characters")
                .required("Please enter your password"),
        }),
        onSubmit: async (values, { resetForm }) => {

            const signInPayload = {
                "emailId": values.email,
                "password": values.password,
                "language": "en",
                "country": "In"
            }

            const token = enCodeJwt(signInPayload);
            const ecodeToken = {
                "authToken": token,
            };
            // try {
            //     const response = await api.publicPost(getLoginUrl() + apiUrls.login.userLogin, ecodeToken);

            //     if (response.data.httpStatusCode === 200) {
            //         localStorage.setItem("token", response.data.response.authToken);
            //         // SetCookie("token", response.data.response.authToken);
    
            //         navigate("/workspace");
            //     } else {
            //         setErrorMessage(response.data.message);
            //         setShowErrorMessage(true);
            //     }
            // } catch (error) {
            //     setErrorMessage(error.response?.data?.message || "An unexpected error occurred. Please try again.");
            //     setShowErrorMessage(true);
            //     console.error("Network or unexpected error:", error);
            // }
            dispatch(SignInDataPost(ecodeToken));
           },
    });
    useEffect(() => {
        if (!isLoadingSignInData && signInData) {
            localStorage.setItem("token", signInData?.response?.authToken);
          navigate('/workspace'); 
        }else if(signInError) {
            setErrorMessage(signInError);
            setShowErrorMessage(true);
        }
      }, [isLoadingSignInData, signInData,signInError]);
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate("/");
        }
    }, []);
    return (
        <div className="signUpContainer">
            <div className="signUpTop">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-md-6">
                            <div className="rightSideSignIn">
                                <div className="custokingLogo">
                                    <img src={Icons.custokingLogo} className="img-fluid" alt="logo" />
                                </div>
                                <p className="text-white font30 fw600 signUpTo">Sign In  to</p>
                                <p className="text-white font20 fw400">Custoking School ERP</p>
                                <p className="text-white font16 fw400">Custoking has developed one of the most advanced and user friendly School ERP Software for managing various school activities.</p>
                            </div>

                        </div>
                        <div className="col-xl-5 col-md-6 position-relative">
                            <div className="loginCard">
                                <div className="welcomeText">
                                    <div>
                                        <p className="font20 fw500 welcomeCustoking">Welcome to <span className="fw600">CustoKing</span></p>
                                    </div>
                                    <div>
                                        <p className="haveAccount fz12 fw400">No Account?</p>
                                        <Link to="/sign-up" className="link-decoration"> <p className="signInText ">Sign up</p></Link>
                                    </div>
                                </div>
                                <div className="signUpText">
                                    <p className="font36 fw600 ">Sign In</p>
                                </div>
                                <div>
                                    <div className="row">
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="col-md-12 pt15">
                                                <TextInputWithLabel label={"Email Address"} placeHolder={"Email Address"}
                                                    name="email"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                    error={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                                                />
                                            </div>
                                            <div className="col-md-12 pt15">
                                                <TextInputWithLabel label={"Enter Your Password"} placeHolder={"Password"}
                                                    name="password"
                                                    type={showPassword ? "text" : "password"}
                                                    rightIcon={showPassword ? Icons.eyeClose : Icons.eyeOpen}
                                                    onPressRight={togglePasswordVisibility}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.password}
                                                    error={formik.touched.password && formik.errors.password ? formik.errors.password : null}
                                                />
                                                <Link to='/forgot-password' className="link-decoration"><p className="goto-forgetPassowrdPage">Forgot Password</p></Link>
                                            </div>
                                            {errorMessage ?
                                                <ToastBootstrap showErrorMessage={showErrorMessage} setShowErrorMessage={setShowErrorMessage} className={"toastErrorMessage"} message={errorMessage} /> : null
                                            }

                                            <div className="col-md-12 pt30">
                                                <ApplicationButton btnText={"Sign In"} type="submit" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn;