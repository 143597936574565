import { useMemo, useState } from "react";

import "./AttandenceTable.css";
import FirstLastNameAvatar from "../../components/avatar/FirstLastNameAvatar";
import { formatDate } from "../../utils/DateFormate";


const attandenceTable = [
  {
    id: "01",
    name: "Arrora Gaur",
    email: "arroragaur@gmail.comkiiiiiiiiiiiiiiiiiiii",
    contact: "9183390098",
  },
  {
    id: "02",
    name: "James Mullican",
    email: "jamesmullican@gmail.com",
    contact: "9183390098",
  },
  {
    id: "03",
    name: "Robert Bacins",
    email: "robertbacins@gmail.com",
    contact: "9183390098",
  },
  {
    id: "05",
    name: "Robert Bacins",
    email: "robertbacins@gmail.com",
    contact: "9183390098",
  },
  {
    id: "06",
    name: "Robert Bacins",
    email: "robertbacins@gmail.com",
    contact: "9183390098",
  },
  {
    id: "07",
    name: "Robert Bacins",
    email: "robertbacins@gmail.com",
    contact: "9183390098",
  },
  {
    id: "08",
    name: "Robert Bacins",
    email: "robertbacins@gmail.com",
    contact: "9183390098",
  },
  {
    id: "",
    name: "Robert Bacins",
    email: "robertbacins@gmail.com",
    contact: "9183390098",
  },
];
;
const dataRangeData = [
  {
    id: 1,
    date: 12,
    status: 'present',
  },
  {
    id: 1,
    date: 13,
    status: 'absent',
  },
  {
    id: 1,
    date: 12,
    status: 'present',
  },
  {
    id: 1,
    date: 12,
    status: 'present',
  },
  {
    id: 1,
    date: 12,
    status: 'late',
  },
  {
    id: 1,
    date: 12,
    status: 'present',
  },
  {
    id: 1,
    date: 12,
    status: 'late',
  },
]

const CustomAttendanceTable = ({ startDate, endDate }) => {
  const selectedStartDate = new Date(startDate);
  const selectedEndDate = new Date(endDate);

  const dateRange = useMemo(() => {
    const dates = [];
    const currentDate = new Date(selectedStartDate);
    while (currentDate <= selectedEndDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }, [selectedStartDate, selectedEndDate]);
  return (
    <>
      <table>
        <thead>
          <tr>
            <th></th>
            {dataRangeData?.map((date, index) => (
              <th key={index} className="text-center">{date.date}</th>
            ))}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {attandenceTable?.map((student) => (
            <tr key={student.id}>
              <td>
                <div className="d-flex align-items-center attadenceTable">
                  <div className="avatarCircle30 font10 fw600">
                    <FirstLastNameAvatar name={student.name} />
                  </div>
                  <span className="ms-2 text-wrap">{student.name}</span>
                </div>
              </td>
              {dataRangeData?.map((date, index) => (
                <td key={index}>
                  <div className="d-flex justify-content-center">
                    <div className={`labelAttendence ${date.status === "present" ? "present" : date.status === "absent" ? "absent" : "late"}`}>
                      <span className="checkmark">{date.status}</span>
                    </div>
                  </div>
                </td>
              ))}
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};


export default CustomAttendanceTable;
