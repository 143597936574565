import React from "react";
import UserLayout from "../../../components/layout/UserLayout";
import ApplicationHeader from "../../../components/applicationHeader/ApplicationHeader";
import "./Home.css";

const Home = () =>{
    localStorage.removeItem("eventResponse");
    return(
        <UserLayout>
            <ApplicationHeader title="Home"/>
          <p>Comming Soon</p>
        </UserLayout>
    )
}

export default Home;