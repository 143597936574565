import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiUrl } from '../../../services/urlConfig/UrlConfig';
import { apiUrls } from '../../../services/endPoints/ApiUrls';
import decodeJWT from '../../../utils/Decode';
import axiosInstance from '../../../services/api/Api';

// Decode the token and get the workspaceId
const token = localStorage.getItem('token');
const encodedToken = decodeJWT(token);
const workspaceIdd = encodedToken?.payload?.workspaceId;


  export const fetchGetAttandence = createAsyncThunk(
    'attandence/fetchGetAttandence',
    async (value, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(getApiUrl()+apiUrls.attandence.getAttendence(value.startDate,value.endDate));
            if (response.status === 200) {
                return response.data.response;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || "An unexpected error occurred. Please try again."
            );
        }
    }
);