

export const apiUrls = {
    prefix: "/api",
    signUp: {
      verificationEmail: `/send/verification-email`,
      verfiyToken: `/verify-token`,
      checkUrl:`/check/url`,
      googleSignUp:`/google-signup`,
      verifyInvitation:`/verify-invitation`
    },
    workspace:{
      createWorkspace :`/workspace/createWorkspace`,
      loginWithWorkspace :`/workspace/login`,
      getAllUserWorkspace:`/workspace/getUserWorkspaces`,
      getWorkspaceById:(id)=>`/api/workspace/getWorkspace/${id}`,
      workspaceLogin:`/workspace/login`
    },
    login:{
      userLogin:`/userAuth/login`
    },
    logout:{
         logoutUser:`/api/logout`
    },
    student: {
      getStudent:(workspaceId)=> `/api/student/getStudents/${workspaceId}`,
      createStudent: `/api/student/createStudents`,
      getStudentCount:(workspaceId)=>`/api/student/count/${workspaceId}`,
      getMatchingStudent:(name)=>`/api/student/getMatchingStudent/${name}`
    },
    attandence:{
      markAttendence:`/api/attendance/mark`,
    getAttendence:(startDate,endDate)=>`/api/attendance/getAttendance?startDate=${startDate}&endDate=${endDate}`
    },
    studentDetails:{
      academicDetails:`/api/sis/saveAcademicDetails`,
      parentDetails: `/api/sis/saveParentDetails`,
      saveAddressDetails: `/api/sis/saveAddressDetails`,
      admissionDetails:`/api/sis/saveAdmissionDetails`,
      financialDetails:`/api/sis/saveFinancialDetails`,
      personalDetails: `/api/sis/savePersonalDetails`,
      previousEducationDetails: `/api/sis/savePreviousEducationDetails`,
      studentDocumnets:`/api/sis/saveStudentDocumnets`,
      studentDetails:(workspaceId)=> `/api/sis/getStudentDetails/${workspaceId}`,
      generalInfo: `/api/sis/updateGeneralInfo`,
      uploadDocument: `/api/sis/saveDocument`,
      fetchDocument: (workspaceId)=> `/api/sis/fetchDocuments/${workspaceId}`,
    }
  
  };
  