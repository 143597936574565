import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { Icons } from "../../constants/Icons";
import ApplicationButton from "../applicationButton/ApplicationButton";
import TextInputWithLabel from "../applicationInput/ApplicationInput";

const EmailAs = ({ show, handleClose, title }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email is required"),
      subject: Yup.string().required("Subject is required"),
      message: Yup.string().required("Message  is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      // setStudents(prevData => [...prevData, values]);
      resetForm();
    },
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="customModal"
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <p className="mb-0 font16 fw500">{title}</p>
            <div>
              <img
                src={Icons.crossSky}
                alt="cross"
                className="crossModal"
                onClick={handleClose}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row w-100 paddingLeft20">
              <div className="col-md-12 paddingBottom6">
                <TextInputWithLabel
                  label="Email"
                  name="email"
                  placeHolder="Notification@custoking.com"
                  customInputStyle=""
                  customLabelStyle="fw600"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null
                  }
                />
              </div>
              <div className="col-md-12 paddingBottom6">
                <TextInputWithLabel
                type="text"
                  label="Subject"
                  name="subject"
                  customInputStyle=""
                  customLabelStyle="fw600"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subject}
                  error={
                    formik.touched.subject && formik.errors.subject
                      ? formik.errors.subject
                      : null
                  }
                />
              </div>
              <div className="col-md-12 paddingBottom6">
                <label
                  htmlFor="message"
                  className="label-text-style fw600 "
                >
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  rows="6"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  placeholder="0/50"
                ></textarea>
                {formik.touched.message && formik.errors.message ? (
                  <div className="Inputerror">{formik.errors.message}</div>
                ) : null}
              </div>
            </div>
            <div className=" w-100 row paddingTop30 paddingLeft20">
              <div className="col-md-6">
                <ApplicationButton
                  btnText={"Cancel"}
                  className={"whiteBtn40"}
                  onClick={handleClose}
                />
              </div>
              <div className="col-md-6">
                <ApplicationButton
                  btnText={"Email"}
                  className={"button40"}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailAs;
