import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiUrl } from '../../../services/urlConfig/UrlConfig';
import { apiUrls } from '../../../services/endPoints/ApiUrls';
import axiosInstance from '../../../services/api/Api';

// Decode the token and get the workspaceId


// Thunk to fetch student data
export const fetchStudentData = createAsyncThunk(
  'student/fetchStudentData',
  async (value, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(getApiUrl() + apiUrls.student.getStudent(value));
      if (response.status === 200) {
        return response.data.response;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);

// Thunk to fetch student count
export const fetchStudentCount = createAsyncThunk(
  'student/fetchStudentCount',
  async (value, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(getApiUrl() + apiUrls.student.getStudentCount(value));
      if (response.status === 200) {
        return response.data.response;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
// Thunk to fetch Matching Student Name
export const fetchMatchingStudentName = createAsyncThunk(
  'student/fetchMatchingStudentName',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(getApiUrl() + apiUrls.student.getMatchingStudent(_));
      if (response.status === 200) {
        return response.data.response;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);

export const fetchAcademicDetailsStudent = createAsyncThunk(
  'student/fetchAcademicDetailsStudent',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        getApiUrl() + apiUrls.studentDetails.academicDetails,
        payload,
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
export const fetchAdmissionDetailsStudent = createAsyncThunk(
  'student/fetchAdmissionDetailsStudent',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        getApiUrl() + apiUrls.studentDetails.admissionDetails,
        payload
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
export const fetchPersonalDetailsStudent = createAsyncThunk(
  'student/fetchPersonalDetailsStudent',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        getApiUrl() + apiUrls.studentDetails.personalDetails,
        payload);

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
export const fetchStudentAddressDetails = createAsyncThunk(
  'student/fetchStudentAddressDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        getApiUrl() + apiUrls.studentDetails.saveAddressDetails,
        payload);

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
export const fetchStudentFinancialDetails = createAsyncThunk(
  'student/fetchStudentFinancialDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        getApiUrl() + apiUrls.studentDetails.financialDetails,
        payload);

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
export const fetchPreviousEducationDetails = createAsyncThunk(
  'student/fetchPreviousEducationDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        getApiUrl() + apiUrls.studentDetails.previousEducationDetails,
        payload);

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
export const fetchParentDetailsDetails = createAsyncThunk(
  'student/fetchParentDetailsDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        getApiUrl() + apiUrls.studentDetails.parentDetails,
        payload);

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
export const postAddStudent = createAsyncThunk(
  'student/postAddStudent',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        getApiUrl() + apiUrls.student.createStudent,
        payload);

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);

export const getStudentDetails = createAsyncThunk(
  'student/getStudentDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(getApiUrl() + apiUrls.studentDetails.studentDetails(_));
      if (response.status === 200) {
        return response.data.response;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
export const updateGeneralInfo = createAsyncThunk(
  'student/updateGeneralInfo',
  async (payload, { rejectWithValue }) => {
    try {
      // Perform a PUT request to update general info
      const response = await axiosInstance.put(
        getApiUrl() + apiUrls.studentDetails.generalInfo,
        payload
      );

      if (response.status === 200) {
        return response.data.response; // Return the response data if the update is successful
      } else {
        return rejectWithValue(response.data.message); // Return the error message from the response
      }
    } catch (error) {
      // Handle any unexpected errors
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
export const uploadDocumentFile = createAsyncThunk(
  'student/uploadDocumentFile',
  async ({ file, otherData }, { rejectWithValue }) => {
    try {
      if (!file) {
        return rejectWithValue('No file selected for upload.');
      }
      const formData = new FormData();
      const dataStringyFY = JSON.stringify(otherData);
      const blob = new Blob([dataStringyFY], {
        type: "application/json",
      });
      formData.append('file', file);
      formData.append("data", blob);
      const customHeaders = {
        'Content-Type': 'multipart/form-data',
      };
      const response = await axiosInstance.post(
        getApiUrl() + apiUrls.studentDetails.uploadDocument,
        formData,
        {
          headers: customHeaders,
        }
      );
      if (response.status === 200) {
        return response.data.response;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'An unexpected error occurred. Please try again.'
      );
    }
  }
);
export const fetchDocuments = createAsyncThunk(
  'student/fetchDocuments',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(getApiUrl() + apiUrls.studentDetails.fetchDocument(data));
      if (response.status === 200) {
        return response.data.response;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);