import React from "react";
import "./Fees.css";
import ApplicationHeader from "../../components/applicationHeader/ApplicationHeader";
import UserLayout from "../../components/layout/UserLayout";
import FeesHeader from "../../components/applicationHeader/feesHeader/FeesHeader";
import FeesTable from "./FeesTable";
const FeesModule = () => {
  return (
    <>
      <UserLayout>
        <ApplicationHeader title="Fees" />
        <FeesHeader />
        <div className="studentTableContainer attandenceTableContainer">
          <FeesTable />
        </div>
      </UserLayout>
    </>
  );
};

export default FeesModule;
