
import React from "react";
import { Icons } from "../../constants/Icons";
import"./Fees.css";
import { useNavigate } from "react-router-dom";


const feesData = [
    {
      studentId: "#876123",
      name: "James Mullican",
      status: "Paid",
      statusColor: "green",
      mode: "Cash",
    },
    {
      studentId: "#876123",
      name: "James Mullican",
      status: "Processing",
      statusColor: "orange",
      mode: "NEFT",
    },
    {
      studentId: "#876123",
      name: "James Mullican",
      status: "Failed",
      statusColor: "red",
      mode: "UPI",
    },
    {
      studentId: "#876123",
      name: "James Mullican",
      status: "Processing",
      statusColor: "orange",
      mode: "UPI",
    },
    {
      studentId: "#876123",
      name: "James Mullican",
      status: "Paid",
      statusColor: "green",
      mode: "UPI",
    },
    {
      studentId: "#876123",
      name: "James Mullican",
      status: "Paid",
      statusColor: "green",
      mode: "UPI",
    },
    {
      studentId: "#876123",
      name: "James Mullican",
      status: "Paid",
      statusColor: "green",
      mode: "UPI",
    },
    {
      studentId: "#876123",
      name: "James Mullican",
      status: "Paid",
      statusColor: "green",
      mode: "UPI",
    },
    {
      studentId: "#876123",
      name: "James Mullican",
      status: "Paid",
      statusColor: "green",
      mode: "UPI",
    },
  ];
  

const FeesTable = () => {
const navigate=useNavigate()
       
    const getFeesDetails=(id)=>{
        console.log(id)
        if (id) {
            localStorage.setItem("feesDto", id);
            navigate('fee-details')
        }
    }
  return (
    <div className="table-responsive">
      <table className="table ">
        <thead>
          <tr>
            <th>Student ID</th>
            <th>Name</th>
            <th>Status</th>
            <th>Mode</th>
            <th className="width80">Reminder</th>
            <th className="width80">Export</th>
          </tr>
        </thead>
        <tbody>
          {feesData.map((fee, index) => (
            <tr key={index}>
              <td >{fee.studentId}</td>
              <td className="cursor-pointer" onClick={() => getFeesDetails(fee.studentId)}>{fee.name}</td>
              <td style={{ color: fee.statusColor }}>{fee.status}</td>
              <td>{fee.mode}</td>
              <td className="width80">
                <img
                  src={Icons.bellRinging}
                  alt="Reminder"
                  className="fees-icon"
                />
              </td>
              <td className="width80">
                <img
                  src={fee.status==="Paid"?Icons.arrowDownCircle:Icons.arrowDownCircleGrey}
                  alt="Export"
                  className="fees-icon"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FeesTable;
